import React, { useState, forwardRef, useEffect, useCallback, useContext } from "react";
import Table from "react-bootstrap/Table";
// import Button from "react-bootstrap/Button";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Dots } from "../../assets/icons/threedots.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePatientAPI,
  editPatientAPI,
  fetchPatientsAPI,
  signinAsUserAPI,
} from "../../redux/slices/myPatients/myPatientsService";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { Select } from "antd";
import { stateList } from "../../utils/constants";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import { toast } from "react-toastify";
import Loader from "../../components/Common/Loader";
import { MdOutlineChat } from "react-icons/md";
import { SocketContext } from "../../context/SocketProvider";
import { initiateIndividualChatIfNotExists } from "../../redux/slices/chat/chatService";
import { getDoctorsUsersAPI } from "../../redux/slices/doctor/doctorService";
import { useHistory } from "react-router-dom";

const { Option } = Select;

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-link p-0"
    >
      {children}
    </button>
  )
);

const PatientsTable = ({ searchTerm }: any) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedDeletePatient, setSelectedDeletePatient] = useState<any>(null);
  const [order, setOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const { patientsList } = useSelector(
    (ReduxState: any) => ReduxState.myPatients
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const history = useHistory();

  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState<any>(configInfo?.cities || []);
  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    if (configInfo?.cities) {
      setCities(configInfo.cities);
    }
  }, [configInfo]);

  const filteredPatients = order?.filter((patient: any) =>
    Object.values(patient).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [docId, setDocId] = useState<any>();
  useEffect(() => {
    if (user?.roles?.length > 0 && user.roles[0].linkable_id) {
      setDocId(user.roles[0].linkable_id); // Set doctor ID from user data
    }
  }, [user]);

  useEffect(() => {
    if (patientsList) {
      const mappedOrder = patientsList?.patients?.map((patient: any) => ({
        serialno: `${patient?.id}`,
        name: `${patient?.first_name} ${patient?.last_name}`,
        age: patient?.age,
        mobilenum: patient?.phone,
        email: patient?.email,
        gender:
          patient?.gender?.charAt(0)?.toUpperCase() + patient?.gender?.slice(1),
        city: "",
        pincode: "",
      }));
      setOrder(mappedOrder);
    }
  }, [patientsList]);

  useEffect(() => {
    setShowLoader(true);
    dispatch(fetchPatientsAPI());
    setShowLoader(false);
  }, [dispatch]);

  const handleShowModal = (patient: any) => {
    setSelectedPatient(patient);
    setModalShow(true);
  };

  const handleEdit = (patient: any) => {
    const chosenPatient = patientsList?.patients?.find((item: any) => {
      return item.id == patient?.serialno;
    });
    console.log("chosenPatient : ", chosenPatient);
    setPatientDetails({
      first_name: chosenPatient?.first_name || "",
      last_name: chosenPatient?.last_name || "",
      name: chosenPatient?.name || "",
      mobile: chosenPatient?.phone || "",
      email: chosenPatient?.email || "",
      employeeId: chosenPatient?.employee_id || "",
      age: chosenPatient?.age || "",
      gender: chosenPatient?.gender || "",
      address: chosenPatient?.address?.address || "",
      state: chosenPatient?.address?.state || "",
      city: chosenPatient?.address?.city || "",
      pinCode: chosenPatient?.address?.zip || "",
      userId: chosenPatient?.id || "",
    });
    handlePatientModalOpen();
  };

  const handleDelete = (patient: any) => {
    console.log("Delete", patient);
    setSelectedDeletePatient(patient);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowLoader(true);
    const res = await dispatch(
      deletePatientAPI(selectedDeletePatient.serialno)
    );
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      setShowDeleteModal(false);
      return;
    } else {
      toast.success("Patient Deleted Successfully");
      dispatch(fetchPatientsAPI());
      setShowDeleteModal(false);
      setShowLoader(false);
    }
  };

  // Get current patients
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients =
    filteredPatients?.slice(indexOfFirstPatient, indexOfLastPatient) || [];

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.max(
    1,
    Math.ceil((filteredPatients?.length || 0) / patientsPerPage)
  );

  const [showPatientModal, setShowPatientModal] = useState(false);

  const [patientDetails, setPatientDetails] = useState({
    first_name: "",
    last_name: "",
    name: "",
    mobile: "",
    email: "",
    employeeId: "",
    age: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    pinCode: "",
    userId: "",
  });

  const handlePatientInputChange = (e: any) => {
    const { name, value } = e.target;
    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePatientModalOpen = () => {
    setShowPatientModal(true);
  };

  const handlePatientModalClose = () => {
    setShowPatientModal(false);
  };

  const handleEditPatient = async () => {
    setShowLoader(true);
    const editBody = {
      id: patientDetails?.userId,
      editPayload: {
        firstName: patientDetails?.first_name,
        age: parseInt(patientDetails?.age),
        phone: patientDetails?.mobile,
        email: patientDetails?.email,
        gender: patientDetails?.gender,
        address: patientDetails?.address,
        state: patientDetails?.state,
        city: patientDetails?.city,
        zip: `${patientDetails?.pinCode}`,
      },
    };
    const res = await dispatch(editPatientAPI(editBody));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson : ", resJson);
    if (resJson?.error) {
      // toast.error(resJson?.error?.message);
      displayMessage(resJson.error.message, "error");
      setShowLoader(false);
      return;
    } else {
      toast.success("Patient Edited Successfully");
      dispatch(fetchPatientsAPI());
      handlePatientModalClose();
      setShowLoader(false);
    }
  };

  const [message, setMessage] = useState(null); // Stores message object
  const [messageType, setMessageType] = useState(""); // 'error' or 'success'

  const displayMessage = (msg: any, type: any) => {
    setMessage(msg);
    setMessageType(type);
    setTimeout(() => {
      setMessage(null);
      setMessageType("");
    }, 7000); // Clear message after 3 seconds
  };

  const handleCreateOrder = async (item: any) => {
    console.log("item : ", item);

    const res = await dispatch(
      signinAsUserAPI({ userid: item?.serialno?.toString() })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    let ssoUrl;
    if (resJson?.payload?.data?.url) {
      ssoUrl = new URL(resJson?.payload?.data?.url);
    }
    if (ssoUrl) {
      ssoUrl.searchParams.set("doctorId", docId);
      const updatedUrl = ssoUrl?.toString();
      console.log("updatedUrl : ", updatedUrl);
      if (resJson?.payload?.data?.url) {
        window.open(updatedUrl, "_self");
      }
    }
  };

  const { userChatId }: any = useContext(SocketContext);

  const handleInitiateChat = useCallback(async ({ id, name , email,image}: { id: any, name: string, email: string, image: string }) => {
    const result : any = await dispatch(initiateIndividualChatIfNotExists({
      from: userChatId, to: id, name, email, image
    }))

    if(result?.payload?.success){
      history.push(`/chat?id=${result?.payload?.data?._id}`)
    }else{
      toast.error("Something went wrong!.")
    }
  }, [userChatId])

  return (
    <div className="patient-table">
      {showLoader && <Loader />}
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Name</th>
            <th>Age</th>
            <th>Mobile Number</th>
            <th>Email Id</th>
            <th>Gender</th>
            <th>City</th>
            <th>Pin Code</th>
            <th className="action-report">Report</th>
            <th className="action-report">Actions</th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody>
          {currentPatients.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.serialno} </td>
              <td>
                <button
                  className="btn btn-link"
                  onClick={() => handleShowModal(item)}
                >
                  {item.name}
                </button>
              </td>
              <td>{item.age} </td>
              <td>{item.mobilenum} </td>
              <td>{item.email} </td>
              <td>{item.gender} </td>
              <td>{item.city}</td>
              <td>{item.pincode}</td>
              <td className="action-report">
                <Report />
              </td>
              <td className="actions">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}>
                    <Share />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <button className="whatsappbtn">
                        <Whatsapp /> WhatsApp
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="mailbtn">
                        <Mail /> Send Email
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span className="icon-space"></span>
                <Call />
                <span className="icon-space"></span>
                {/* <Download /> */}
                <MdOutlineChat
                onClick={() =>{
                  handleInitiateChat({
                    email: item?.email,
                    id:item?.serialno,                  
                    image:item?.image,
                    name: item?.name
                  })
                }}
                />
                <span className="icon-space"></span>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}>
                    <Dots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleEdit(item)}
                      className="edit-text"
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDelete(item)}
                      className="edit-text"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                <Button
                  variant="primary"
                  className="btn create-order-btn"
                  onClick={() => handleCreateOrder(item)}
                >
                  Create Order
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="pagination-container">
        {totalPages > 0 ? (
          <div className="pagination">
            <Pagination className="custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  paginate(
                    currentPage < totalPages ? currentPage + 1 : currentPage
                  )
                }
              />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        ) : (
          <div className="no-data text-center">
            <p>No data available currently</p>
            <img
              src="https://img.freepik.com/premium-vector/no-data-found-empty-file-folder-concept-design-vector-illustration_620585-1698.jpg?semt=ais_hybrid"
              alt="No Data Available"
              className="img-fluid"
              style={{ width: "150px", height: "150px" }}
            />
          </div>
        )}
      </div>

      <Modal show={showPatientModal} onHide={handlePatientModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="patientFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter First Name"
                    name="first_name"
                    value={patientDetails.first_name}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="patientLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Last Name"
                    name="last_name"
                    value={patientDetails.last_name}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="patientMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter Mobile number"
                    name="mobile"
                    value={patientDetails.mobile}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="patientEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Please enter Email ID"
                    name="email"
                    value={patientDetails.email}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="patientEmployeeId">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Employee ID"
                    name="employeeId"
                    value={patientDetails.employeeId}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="patientAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Age"
                    name="age"
                    value={patientDetails.age}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="patientGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={patientDetails.gender}
                    onChange={handlePatientInputChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="patientAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please type & select address"
                    name="address"
                    value={patientDetails.address}
                    onChange={handlePatientInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="patientState">
                  <Form.Label>State</Form.Label>
                  <Select
                    showSearch
                    value={patientDetails.state}
                    style={{ width: "100%" }}
                    placeholder="Select a state"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setPatientDetails((prevDetails) => ({
                        ...prevDetails,
                        state: value,
                      }));
                    }}
                    filterOption={(input, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownStyle={{ zIndex: 9999 }}
                  >
                    {stateList.map((state) => (
                      <Option key={state.value} value={state.value}>
                        {state.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="patientCity">
                  <Form.Label>City</Form.Label>
                  <Select
                    showSearch
                    value={patientDetails.city}
                    style={{ width: "100%" }}
                    placeholder="Select a city"
                    optionFilterProp="children"
                    onChange={(value) => {
                      const selectedCity = cities.find(
                        (city: any) => city.id === value
                      );
                      setPatientDetails({
                        ...patientDetails,
                        city: selectedCity ? selectedCity.id : value,
                      });
                    }}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownStyle={{ zIndex: 9999 }}
                  >
                    {cities?.map((city: any) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="patientPinCode">
              <Form.Label>Pin Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Pin Code"
                name="pinCode"
                value={patientDetails.pinCode}
                onChange={handlePatientInputChange}
              />
            </Form.Group>
            {message && (
              <div
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "#fff",
                  backgroundColor: messageType === "error" ? "red" : "green",
                }}
              >
                {message}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePatientModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditPatient}>
            Edit Patient
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Patient? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedPatient && (
        <PatientDetailModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          patient={selectedPatient}
        />
      )}
    </div>
  );
};

export default PatientsTable;
