import { NutritionDietsStyled } from "./NutritionDiets.styled";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalStyled } from "./ModalStyle.styled";
import {
  createBkDiet,
  generateDietPlan,
} from "../../redux/slices/prescription/prescriptionService";
import { toast } from "react-toastify";
import Loader from "./../../components/Common/Loader";

const cards = [
  {
    name: "Non vegetarian",
    subtitle: "Chicken , Red MeatFish, Prawns etc",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354580137.png",
    button: "Non vegetarian",
  },
  {
    name: "vegetarian",
    subtitle: "Vegetarian-no egg as well",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354561978.png",
    button: "vegetarian",
  },
  {
    name: "Eggetarian",
    subtitle: "Vegetarian with egg dishes",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354536033.png",
    button: "Eggetarian",
  },
];

const cardsDetails = [
  {
    name: "North Indian",
    subtitle: "Roti, Parantha, Sabjis Rajma Chawal etc",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354605965.png",
    button: "North Indian",
  },
  {
    name: "South Indian",
    subtitle: "Idly, Dosa, lemon rice Upma  etc",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354618903.png",
    button: "South Indian",
  },
  {
    name: "Continental",
    subtitle: "Porridge, Breads,Pastas etc",
    img: "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/106435-1737354634251.png",
    button: "Continental",
  },
];

const foodList = [
  {
    fName: "Poultry",
  },
  {
    fName: "Pork",
  },
  {
    fName: "Egg",
  },
  {
    fName: "Lamb/mutton",
  },
  {
    fName: "Beef",
  },
  {
    fName: "Dairy",
  },
  {
    fName: "Gluten",
  },
  {
    fName: "Lactose",
  },
  {
    fName: "Nuts",
  },
  {
    fName: "Soy",
  },
  {
    fName: "Seafood",
  },
];

const NutritionDiets = ({
  showModal,
  setShowModal,
  bkId,
}: {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  bkId: string;
}) => {
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false);
  const [progress, setProgress] = useState(33.33);
  const [selectedDiet, setSelectedDiet] = useState("");
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>([]);
  const [selectedCuisine, setSelectedCuisine] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowModal(false);
    setSelectedDiet("");
    setSelectedAllergies([]);
    setSelectedCuisine("");
    setProgress(33.33);
  };

  const handleCardClick = (diet: string) => {
    setSelectedDiet(diet);
    setProgress(66.66);
  };

  const handleAllergyChange = (fName: string, isChecked: boolean) => {
    setSelectedAllergies((prev) =>
      isChecked ? [...prev, fName] : prev.filter((item) => item !== fName)
    );
  };

  const handleCuisineSelect = async (cuisine: string) => {
    setIsLoader(true);
    setSelectedCuisine(cuisine);
    const dietaryData = {
      dietPreference: selectedDiet,
      FoodAllergies: selectedAllergies,
      cuisine: cuisine,
    };
    console.log("Selected Dietary Data:", dietaryData);
    const res: any = await dispatch(generateDietPlan(dietaryData));
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      setIsLoader(false);
      return;
    }
    console.log("generateDietPlan res : ", res?.payload?.dietPlan);
    if (res?.payload?.dietPlan) {
      addDietToBooking(res?.payload?.dietPlan || {});
    }
    setIsLoader(false);
    history.push(`/dietReport/${bkId}`);
  };

  const addDietToBooking = async (dietPlan: any) => {
    if (!bkId) {
      toast.error("Booking Id is not available");
      return;
    }
    const payload = {
      booking_id: bkId,
      dietPlan: dietPlan,
    };
    const res: any = await dispatch(createBkDiet(payload));
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    }
    console.log("createBkDiet res : ", res?.payload?.data?.data);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      {isLoader && <Loader />}

      <NutritionDietsStyled>
        <ModalStyled>
          <Modal.Header closeButton className="border-0 me-2 mt-2" />
          <div className="w-100 text-center Title">
            {!selectedDiet && (
              <div className="header-title">
                <h4>What is Your Dietary Preference?</h4>
                <p>Your diet will include foods based on this.</p>
              </div>
            )}
            {selectedDiet && !selectedCuisine && (
              <div className="header-title">
                <h4>Do you have any Food Allergies?</h4>
                <p>Your diet plan will exclude these foods.</p>
              </div>
            )}
            {selectedCuisine && (
              <div className="header-title">
                <h4>Which cuisines should we include in your diet?</h4>
                <p>Select as many cuisines as you like</p>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center">
            <div className="progress w-50">
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          {!selectedDiet && (
            <div className="container d-flex justify-content-center mt-4 mb-4">
              <div className="row text-center">
                {cards.map((card, index) => (
                  <div key={index} className="col-md-4 mb-4">
                    <div className="card cursor-pointer">
                      <img
                        src={card.img}
                        alt={card.name}
                        className="card-img"
                      />
                      <div className="card-body">
                        <div className="name-div">
                          <p className="text-title">{card.name}</p>
                          <p className="text-body">{card.subtitle}</p>
                        </div>
                        <button
                          className="card-button btn btn-primary"
                          onClick={() => handleCardClick(card.name)}
                        >
                          {card.button}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectedDiet && !selectedCuisine && (
            <div className="container mt-4 mb-4">
              <div className="row mt-4">
                {foodList.map((item, index) => (
                  <div key={index} className="col-md-3 mb-3">
                    <div className="card-div">
                      <div className="card-body-div d-flex align-items-center">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={selectedAllergies.includes(item.fName)}
                            onChange={(e) =>
                              handleAllergyChange(item.fName, e.target.checked)
                            }
                          />
                          <div className="checkbox-circle">
                            <svg viewBox="0 0 52 52" className="checkmark">
                              <circle cx="26" cy="26" r="25" />
                              <path d="M16 26l9.2 8.4 17.4-21.4" />
                            </svg>
                          </div>
                        </label>
                        <p className="foodName">{item.fName}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setProgress(100);
                    setSelectedCuisine("cuisine");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {selectedCuisine && (
            <div className="container d-flex justify-content-center mt-4 mb-4">
              <div className="row text-center">
                {cardsDetails.map((card, index) => (
                  <div key={index} className="col-md-4 mb-4">
                    <div className="card cursor-pointer">
                      <img
                        src={card.img}
                        alt={card.name}
                        className="card-img"
                      />
                      <div className="card-body">
                        <div className="name-div">
                          <p className="text-title">{card.name}</p>
                          <p className="text-body">{card.subtitle}</p>
                        </div>
                        <button
                          className="card-button btn btn-primary"
                          onClick={() => handleCuisineSelect(card.name)}
                        >
                          {card.button}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ModalStyled>
      </NutritionDietsStyled>
    </Modal>
  );
};

export default NutritionDiets;
