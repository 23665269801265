import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { get, patch, post, put } from "../../../library/Requests/helpers";
import {
  getAllMedicinesQuery,
  getAllTestsQuery,
} from "../../../Scenes/graphqlConfig";

export const getAllMedicinesAPI = createAsyncThunk(
  "auth/getAllMedicinesAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getAllMedicinesQuery))
);

export const getAllTestsAPI = createAsyncThunk(
  "auth/getAllTestsAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getAllTestsQuery))
);

export const getPrescriptionDataAPI = createAsyncThunk(
  "auth/getPrescriptionDataAPI",
  async (id: any) =>
    await get(
      `${SERVER_IP}/api/v1/prescription/getPrescription?bookingId=${id}`
    )
);

export const googleSearchAPI = createAsyncThunk(
  "auth/googleSearchAPI",
  async (query: any) =>
    await get(`${SERVER_IP}/api/v1/filter/suggestions/?q=${query}`)
);

export const googleTranslateAPI = createAsyncThunk(
  "auth/googleTranslateAPI",
  async (body: any) =>
    await get(
      `${SERVER_IP}/api/v1/filter/googleTranslation?q=${body?.query}&tl=${body?.targetLang}`
    )
);

export const uploadPdfPrescription = createAsyncThunk(
  "auth/uploadPdfPrescription",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/prescription/uploadPrescription`, body)
);

export const generateDietPlan = createAsyncThunk(
  "openai/generateDietPlan",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/openAi/generate-diet-plan`, body)
);

export const createBkDiet = createAsyncThunk(
  "diet/createBkDiet",
  async (body: any) => await post(`${SERVER_IP}/api/v1/diet`, body)
);

export const getBkDiet = createAsyncThunk(
  "diet/getBkDiet",
  async (id: any) => await get(`${SERVER_IP}/api/v1/diet/${id}`)
);
